import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import imgLogo from "../assets/image/logo-main-black.png";
import imgError from "../assets/image/svg/404.svg";
import axiosConfig from '../axiosConfig';

const About = () => {
	const [aboutData, setAboutData] = useState({ title: "", image: "" });
  useEffect(() => {
	axiosConfig.get('/about')
      .then(response => {
        if (response.data.success) {
			console.log(response.data.data);
          setAboutData(response.data.data);
          
        } else {
          console.error('Failed to fetch  text');
        }
      })
      .catch(error => {
        console.error('Error fetching  text:', error);
      });
  }, []);
	
  return (
    <>
      <PageWrapper>
        
		<section class="pt-13 pt-lg-30 pb-13 pb-lg-30">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-5 col-md-8 col-sm-10 col-xs-11 mb-9 mb-lg-0 aos-init aos-animate" data-aos="fade-right" data-aos-duration="800">
                <div class="position-relative pr-lg-20 pr-xs-15 pr-9">
                   <img src={aboutData.image} alt="" className="w-100" />
                    <div class="abs-content pos-abs-br bg-white shadow-2 pl-7 pt-8 pb-1 pr-11 max-width-px-311 rounded mb-15 mb-xs-18 mb-lg-15 mb-xl-18">
                        
                    </div>
                </div>
            </div>
            
            <div class="col-xl-7 col-lg-6 col-md-8 col-xs-10 aos-init aos-animate" data-aos="fade-left" data-aos-duration="800">
                <div class="content-1 pl-xl-5 pl-xxl-11 pr-xxl-10">
                    <h2 className="font-size-9 mb-md-15 mb-13">{aboutData.title}</h2>
                   
                    <p className="text-gray font-size-5 mb-10 mb-lg-16">
                   {aboutData.description}
                </p>
                
                    
                   
                </div>
            </div>
        </div>
    </div>
</section>

		
		
		
      </PageWrapper>
    </>
  );
};
export default About;
